import { SidenavLayout } from '@octano/global-ui';
import { Redirect, useRouteMatch } from 'react-router';
import AddemicLogoExpanded from '../assets/addemic/logo_expanded.svg';
import AddemicLogo from '../assets/addemic/logo_small.svg';
import { CampusFooter } from '../components/CampusFooter/CampusFooter';
import RoutesByLayout from '../components/routes/RoutesByLayout';
import TopBar from '../components/TopBar/TopBar';
import { IS_ADDEMIC } from '../config/constants';
import { PathsLayouts } from '../config/routes';
import { useSidenavLinks } from '../config/sidenav';
import { useUserState } from '../hooks/useUserState';

interface TopBarTitlesDict {
  [route: string]: string;
}

const TITLES_KEY_DICTIONARY: TopBarTitlesDict = {
  '/classes': 'classes',
  '/grade-entry': 'classes',
  '/reports': 'classes',
  '/period-end': 'periodEnd',
  '/learning-result': 'learningResult',
  '/courses': 'courses',
};

export default function AuthorizedLayout() {
  const routeMatch = useRouteMatch();
  const { isLogged } = useUserState();

  const sidenavLinks = useSidenavLinks();

  if (!isLogged) {
    return <Redirect to={PathsLayouts.auth} />;
  }

  return (
    <SidenavLayout
      links={sidenavLinks}
      logo={IS_ADDEMIC ? ADDEMIC_LOGOS : CAMPUS_LOGOS}
    >
      <div className="d-flex flex-column h-100">
        <TopBar titleKey={TITLES_KEY_DICTIONARY[routeMatch.path]} />
        <div style={{ flexGrow: 1 }}>
          <RoutesByLayout pathLayout={routeMatch.path as PathsLayouts} />
        </div>
        <CampusFooter />
      </div>
    </SidenavLayout>
  );
}

const CAMPUS_LOGOS = {
  desktop: {
    src: '/tenant/logo_expanded.svg',
    fallbackSrc: '/tenant/logo_expanded.svg',
  },
  mobile: {
    src: '/tenant/logo_small.svg',
    fallbackSrc: '/tenant/logo_small.svg',
  },
};

const ADDEMIC_LOGOS = {
  desktop: {
    src: AddemicLogoExpanded,
    fallbackSrc: AddemicLogoExpanded,
  },
  mobile: {
    src: AddemicLogo,
    fallbackSrc: AddemicLogo,
  },
};
