import { Button, ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import Tooltip from '../../../components/tooltip/Tooltip';
import { PathsLayouts } from '../../../config/routes';
import { TranslationsKeys } from '../../../locales/translations';
import { CourseTable } from '../types';

const useColumnsCoursesList = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_LIST);
  const history = useHistory();

  const showCourse = useCallback(
    (courseId: number) => {
      history.push(`${PathsLayouts.courses}/${courseId}`);
    },
    [history],
  );

  const columns = useMemo<ColumnTable<CourseTable>[]>(() => {
    return [
      {
        columnName: 'code',
        headerText: t('columns.code'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'name',
        headerText: t('columns.name'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'school',
        headerText: t('columns.school'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'studyplan',
        headerText: t('columns.studyPlan'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: ({ row, index }) => {
          return (
            <div>
              {row.studyplan?.length > 1 ? (
                <Tooltip
                  id={`info_tooltip_${row.id}`}
                  placement="right"
                  text={row.studyplan.join(', ')}
                >
                  {t('columns.helpTooltip')}{' '}
                  <Icon name="information" size={14.71} />
                </Tooltip>
              ) : (
                row.studyplan[0]
              )}
            </div>
          );
        },
      },
      {
        columnName: 'modality',
        headerText: t('columns.modality'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        hideOnMobile: true,
      },
      {
        columnName: 'credits',
        headerText: t('columns.credits'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'action',
        headerText: t('columns.action'),
        thClassName: 'text-center',
        cellFormat: ({ row, index }) => {
          const textEvaluation = t('actions.see');

          return (
            <Row>
              <Col className="mb-2">
                <Button
                  text={textEvaluation}
                  size="sm"
                  fullwidth
                  onClick={() => showCourse(row.id)}
                />
              </Col>
            </Row>
          );
        },
      },
    ];
  }, [t]);

  return columns;
};

export default useColumnsCoursesList;
