import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import DisplayError from '../../../components/Info/DisplayError';
import Loading from '../../../components/Info/Loading';
import { TranslationsKeys } from '../../../locales/translations';
import { baseToSelectOption } from '../../../utils/selectFormat';
import {
  getSchoolListRequest,
  requestCourseTypes,
  requestGetCourse,
} from '../api';
import {
  Course,
  CourseFormData,
  RetakeExamAllowedCode,
} from '../types/details';

export interface CourseFormContextInterface {
  courseId?: number;
  course?: Course;
  isLoading: boolean;
  formData: CourseFormData;
}

export const CourseFormContext = createContext<CourseFormContextInterface>({
  isLoading: false,
  formData: {},
});

type CourseProviderProps = {
  courseId: string;
  children: ReactElement;
};

const CourseProvider = ({ courseId, children }: CourseProviderProps) => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const [course, setCourse] = useState<Course>();
  const [formData, setFormData] = useState<CourseFormData>({});

  const [isLoadingCourse, setIsLoadingCourse] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<string>();

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isMountedForm, setIsMountedForm] = useState<boolean>(false);

  const isLoading = useMemo(() => isLoadingCourse || isLoadingForm, [
    isLoadingForm,
    isLoadingCourse,
  ]);

  const fetchCourse = useCallback(
    async (id: number) => {
      if (isLoadingCourse) {
        return;
      }
      setIsLoadingCourse(true);
      try {
        const { data: response, error } = await requestGetCourse(id);
        if (error || !response?.data) {
          throw new Error('ERROR');
        }
        setCourse(response?.data as any);
      } catch (error) {
        setCourse(undefined);
        setErrorLoading('ERROR');
      }
      setIsLoadingCourse(false);
    },
    [isLoadingCourse],
  );

  const fetchFormData = useCallback(async () => {
    if (isLoadingForm) {
      return;
    }
    setIsLoadingForm(true);
    try {
      const [
        { data: formDataResponse, error: errorFormData },
        { data: courseTypesResponse, error: errorCoursesTypes },
      ] = await Promise.all([getSchoolListRequest(), requestCourseTypes()]);
      if (errorFormData || errorCoursesTypes) {
        throw new Error('ERROR');
      }
      setFormData({
        schools: baseToSelectOption(formDataResponse?.schools),
        academicLevels: baseToSelectOption(formDataResponse?.academicLevels),
        activities: baseToSelectOption(formDataResponse?.activities),
        courseTypes: baseToSelectOption(courseTypesResponse?.data),
        modalities: baseToSelectOption(formDataResponse?.modalities),
        studyPlanVersions: baseToSelectOption(
          formDataResponse?.studyPlanVersions,
        ),
        retakeExamOptions: [
          {
            label: t(`retakeExamAllowed_${RetakeExamAllowedCode.Disabled}`),
            value: RetakeExamAllowedCode.Disabled,
          },
          {
            label: t(`retakeExamAllowed_${RetakeExamAllowedCode.Enabled}`),
            value: RetakeExamAllowedCode.Enabled,
          },
        ],
        coursesFeatures: formDataResponse?.coursesFeatures,
      });
    } catch (error) {
      setFormData({});
      setErrorLoading('ERROR');
    }
    setIsLoadingForm(false);
  }, [isLoadingForm, t]);

  const refetch = useCallback(() => {
    setErrorLoading(undefined);
    fetchFormData();
    if (courseId) {
      fetchCourse(+courseId);
    }
  }, [courseId, fetchFormData, fetchCourse]);

  useEffect(() => {
    if (!isMounted && courseId) {
      fetchCourse(+courseId);
      setIsMounted(true);
    }
  }, [isMounted, courseId, fetchCourse]);

  useEffect(() => {
    if (!isMountedForm) {
      fetchFormData();
      setIsMountedForm(true);
    }
  }, [isMountedForm, fetchFormData]);

  if (isLoading && !errorLoading) {
    return (
      <Card className="p-4 mt-2 mx-3">
        <div className="mx-3">
          <Loading insideCard textBody={''} />
        </div>
      </Card>
    );
  }

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={refetch}
          loadingAction={isLoading}
        />
      </div>
    );
  }

  return (
    <CourseFormContext.Provider
      value={{
        courseId: courseId ? +courseId : undefined,
        isLoading,
        course,
        formData,
      }}
    >
      <Card className="p-4 mt-2 mx-3">{children}</Card>
    </CourseFormContext.Provider>
  );
};

export default CourseProvider;
