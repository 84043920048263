import { SelectOptionType } from '@octano/global-ui';

export type AcademicLevelListItem = {
  id: number;
  name: string;
};

export type SchoolType = {
  id: number;
  code: string;
  name: string;
  description: string;
};

export interface CoursesFeaturesOptions {
  retakeExamAllowed: boolean;
  retakeEvaluationAllowed: boolean;
  workingDaysToResumeEvaluation: number | null;
}

type Base = {
  id: number;
  name: string;
};

export type CourseFormData = Partial<{
  schools: SelectOptionType[];
  academicLevels: SelectOptionType[];
  activities: SelectOptionType[];
  modalities: SelectOptionType[];
  studyPlanVersions: SelectOptionType[];
  courseTypes: SelectOptionType[];
  retakeExamOptions: SelectOptionType[];
  coursesFeatures?: CoursesFeaturesOptions | null;
}>;

export type CourseSyllabus = {
  id: number;
  name: string;
  extension: string;
  url?: string;
};

export type CourseAttendance = {
  minPercentage: number;
  activityTypeId: number;
};

export type CourseAssistanceRequirements = CourseAttendance & {
  id: number;
  courseId: number;
  createdAt: string;
  updatedAt: string;
};

export type Course = {
  id: number;
  shortening: string;
  code: string;
  name: string;
  credits: number;
  schoolId: number;
  academicLevelId: number;
  modalityId: number | null;
  retakeExamAllowed: boolean;
  semesterCourses?: any[];
  sections?: any[];
  types: Base[];
  assistanceRequirements: CourseAssistanceRequirements[];
  compatibilities: CourseCompatibility[];
  dependsOn: CoursePrerequisite[];
  syllabus: CourseSyllabus | null;
  createdAt: string;
  updatedAt: string;
  courseLearningResults: CourseLearningResult[];
};

export type CourseCompatibility = {
  id: number;
  shortening: string;
  name: string;
  credits: number;
};

export type CoursePrerequisite = {
  id: number;
  shortening: string;
  name: string;
  credits: number;
};

export type CourseLearningResult = {
  id?: number;
  code?: string;
  description?: string;
  indexOnTable?: number;
};

export type CourseFormFields = {
  // CourseDetailsSection
  shortening: Course['shortening'];
  code: Course['code'];
  name: Course['name'];
  credits: string;
  school: SelectOptionType | null | undefined;
  academicLevel: SelectOptionType | null | undefined;
  modality: SelectOptionType | null | undefined;
  retakeExamAllowed: SelectOptionType | null | undefined;
  // CourseTypeSection
  courseTypes: number[];
  // CourseActivityTypeSection
  attendance: CourseAttendance[];
  // CourseSyllabusSection
  syllabusFile: File | null;
  // CourseCompatibilitiesSection
  compatibilities: CourseCompatibility[];
  // CoursePrerequisitesSection
  prerequisites: CoursePrerequisite[];
  // CourseFooterSection
  learningResults: CourseLearningResult[];
};

export enum RetakeExamAllowedCode {
  Enabled = 'enabled',
  Disabled = 'disabled',
}
