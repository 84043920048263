import {
  TableCollapsable,
  Table,
  TextAreaInput,
  Button,
  addToast,
} from '@octano/global-ui';
import { Card, Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TranslationsKeys } from '../../locales/translations';
import {
  associateItems,
  downloadSectionReport,
  getItemsToAssociate,
  getItemsToAssociateForm,
} from './api';
import { useLearningResultAssociateColumns } from './hooks/useLearningResultAssociateColumns';
import {
  CourseLearningResult,
  LearningResultItem,
  LearningResultTestGradeItem,
} from './types';
import SectionDetails from './parts/SectionDetails';
import GoBackButton from '../../components/Button/GoBackButton';
import LearningResultAssociateSummary from './parts/LearningResultAssociateSummary';
import { createFormData } from './utils';
import { downloadFromBlob } from '../../utils/blob';
import DisplayError from '../../components/Info/DisplayError';

const LearningResultAssociate = () => {
  const { sectionId }: { sectionId: string } = useParams();
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_ASSOCIATE);

  const [section, setSection] = useState<LearningResultItem<undefined>>();
  const [items, setItems] = useState<LearningResultTestGradeItem[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [formData, setFormData] = useState<CourseLearningResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [downloading, setDownloadLoading] = useState<boolean>(false);
  const [hasUnsavedChange, setHasUnsavedChanges] = useState<boolean>(false);

  const [editable] = useState<boolean>(true);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const handleChangeTestGradeOption = useCallback(
    (index: number, courseLearningResult: number | null) => {
      const nextItems = [...items];
      nextItems[index].courseLearningResultId = courseLearningResult;
      setHasUnsavedChanges(true);
      if (courseLearningResult) {
        setItems(nextItems);
      } else {
        setItems([]);
        setLoading(true);
        setTimeout(() => {
          setItems(nextItems);
          setLoading(false);
        }, 1);
      }
    },
    [items],
  );

  const handleChangeNotes = useCallback(
    (index: number, next: string) => {
      const nextItems = [...items];
      nextItems[index].notes = next;
      setItems(nextItems);
      setHasUnsavedChanges(true);
    },
    [items],
  );

  const handleChangeFile = useCallback(
    (index: number, next?: File | null) => {
      const nextItems = [...items];
      nextItems[index].file = next;
      nextItems[index].overrideFile = true;
      setItems(nextItems);
      setHasUnsavedChanges(true);
    },
    [items],
  );

  const handleDownload = useCallback(async () => {
    if (downloading) {
      return;
    }
    setDownloadLoading(true);
    const response = await downloadSectionReport(sectionId);
    const { data, error } = response;

    if (error || !data) {
      addToast({
        text: t(`report.error`),
        color: 'danger',
        icon: 'error',
      });
    } else {
      addToast({
        text: t(`report.success`),
        color: 'success',
        icon: 'success',
      });
      const blob = new Blob([data], {
        type: 'vnd.ms-excel',
      });
      downloadFromBlob(blob, `resultados.xls`);
    }
    setDownloadLoading(false);
  }, [downloading, sectionId, t]);

  const columns = useLearningResultAssociateColumns({
    options: formData,
    availableOptions: formData.filter(
      (o) => !items.some((e) => e.courseLearningResultId === o.id),
    ),
    editable,
    onChangeTestGradeOption: handleChangeTestGradeOption,
  });

  const fetchData = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const [{ data, error }, { data: form }] = await Promise.all([
      getItemsToAssociate(sectionId),
      getItemsToAssociateForm(sectionId),
    ]);

    if (!error && data) {
      setSection(data?.section);
      setItems(data?.testGrades ?? []);
      setFormData(form ?? []);
      setAvailable(data?.available);
      setNotes(data?.notes?.trim() || '');
    } else {
      setSection(undefined);
      setNotes('');
      setAvailable(false);
      setFormData([]);
      setItems([]);
    }
    setLoading(false);
  }, [loading, sectionId]);

  const handleSave = useCallback(async () => {
    if (saving) {
      return;
    }
    setSaving(true);
    const { error } = await associateItems(
      sectionId,
      createFormData({
        items: items,
        notes: notes?.trim() || null,
      }),
    );
    if (!error) {
      addToast({
        text: t(`save.success`),
        color: 'success',
        icon: 'success',
      });
      await fetchData();
      setHasUnsavedChanges(false);
    } else {
      addToast({
        text: t(`save.error`),
        color: 'danger',
        icon: 'error',
      });
    }

    setSaving(false);
  }, [saving, sectionId, notes, items, fetchData, t]);

  useEffect(() => {
    if (!isMounted) {
      fetchData();
      setIsMounted(true);
    }
  }, [isMounted, fetchData]);

  return (
    <>
      <Card className="m-3 py-4">
        <Container fluid>
          <Row>
            <div
              className="w-100 d-flex px-3 align-items-center justify-content-center flex-wrap"
              style={{ gap: 8 }}
            >
              <div className="flex-fill">
                <GoBackButton />
              </div>
              {!!section && <SectionDetails section={section} />}
            </div>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <h2 className="text-primary fs-20 fw-700 mb-0">{t(`title`)}</h2>
              <p className="text-light fs-14 mb-5">{t(`description`)}</p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} className="g-table-evaluations-ra">
              <TableCollapsable
                columns={columns}
                data={items}
                isLoading={loading}
                isCollapsable={(row) => !!row?.qualitativeAssessments}
                noResultsText={''}
                secondColumn={{
                  columnName: 'second',
                  headerText: 'second',
                  cellFormat: ({ row, index }) => (
                    <LearningResultAssociateSummary
                      row={row}
                      editable={editable}
                      onChangeNotes={(next) => handleChangeNotes(index, next)}
                      onChangeFile={(next) => handleChangeFile(index, next)}
                    />
                  ),
                }}
              />

              {!loading && !available && (
                <DisplayError
                  title={t(`notAvailableTitle`)}
                  textBody={t(`notAvailable`)}
                  iconName="information"
                  insideCard
                />
              )}
            </Col>
            {!!(!loading && available) && (
              <>
                <Col xs={12}>
                  <hr />
                </Col>
                <Col xs={12} className="mt-5">
                  <h2 className="text-primary fs-20 fw-700 mb-0">
                    {t(`notesLabel`)}
                  </h2>
                </Col>
                <Col xs={12}>
                  <TextAreaInput
                    name="notes"
                    label=""
                    placeholder={t(`notesDescription`)}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      setHasUnsavedChanges(true);
                    }}
                    disabled={!editable}
                  />
                </Col>
                <Col className="mt-5" xs={12}>
                  <div className="w-100 d-flex flex-wrap" style={{ gap: 8 }}>
                    <div className="flex-fill" />
                    <Button
                      text={t(`report`)}
                      type="button"
                      outlined
                      style={{ maxWidth: '100%', width: 275 }}
                      loading={downloading}
                      disabled={
                        !editable ||
                        saving ||
                        items?.some((e) => !e?.courseLearningResultId) ||
                        hasUnsavedChange
                      }
                      onClick={handleDownload}
                    />
                    <Button
                      text={t(`save`)}
                      disabled={!editable || downloading}
                      loading={saving}
                      style={{ maxWidth: '100%', width: 275 }}
                      onClick={handleSave}
                      type="button"
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Card>
    </>
  );
};

export default LearningResultAssociate;
